import labels from "../Mentors/const"

export default {
  en: {
    title: "",
  },
  pl: {
    home: {
      numbers: [
        {
          value: 48,
          text: "godzin",
          image: require("./images/ico-24.svg").default,
        },
        {
          //value: labels.pl.mentors.length - 1,
          value: 30,
          text: "mentorów",
          image: require("./images/ico-mentor.svg").default,
        },
        {
          value: 100,
          text: "uczestników",
          image: require("./images/ico-uczestnicy.svg").default,
        },
        {
          value: 3,
          text: "ścieżki",
          image: require("./images/ico-planeta.svg").default,
        },
        // {
        //   value: 1,
        //   text: "lokalizacja",
        //   image: require("./images/ico-uczestnicy.svg").default,
        // },
      ],
    },
    summary2024: {
      title: "Edycja 2024 w liczbach",
      numbers: [
        {
          value: 48,
          text: "godzin",
          image: require("./images/ico-24.svg").default,
        },
        {
          value: 30,
          text: "mentorów",
          image: require("./images/ico-mentor.svg").default,
        },
        {
          value: 100,
          text: "uczestników",
          image: require("./images/ico-uczestnicy.svg").default,
        },
        {
          value: 3,
          text: "ścieżki",
          image: require("./images/ico-planeta.svg").default,
        },
        {
          value: "Spływ kajakowy",
          text: "połączony ze sprzątaniem ziemi",
          image: require("./images/ico-24.svg").default,
        },
      ],
    },
    summary2023: {
      title: "Edycja 2023 w liczbach",
      numbers: [
        {
          value: 48,
          text: "godzin",
          image: require("./images/ico-24.svg").default,
        },
        {
          value: labels.pl.mentors.length - 1,
          text: "mentorów",
          image: require("./images/ico-mentor.svg").default,
        },
        {
          value: 3,
          text: "ścieżki",
          image: require("./images/ico-planeta.svg").default,
        },
        {
          value: 1,
          text: "lokalizacja",
          image: require("./images/ico-uczestnicy.svg").default,
        },
        {
          value: "Spływ kajakowy",
          text: "połączony ze sprzątaniem ziemi",
          image: require("./images/ico-24.svg").default,
        },
      ],
    },
    summary2022: {
      title: "Edycja 2022 w liczbach",
      numbers: [
        {
          value: ">150",
          text: "uczestników i<br />mentorów",
          image: require("./images/ico-24.svg").default,
        },
        {
          value: 2,
          text: "lokalizacje",
          image: require("./images/ico-mentor.svg").default,
        },
        {
          value: 14,
          text: "zespołów",
          image: require("./images/ico-planeta.svg").default,
        },
        {
          value: 14,
          text: "warsztatów",
          image: require("./images/ico-uczestnicy.svg").default,
        },
        {
          value: "Spływ kajakowy",
          text: "połączony ze sprzątaniem ziemi",
          image: require("./images/ico-24.svg").default,
        },
        {
          value: "5000 osób",
          text: "mailing bezpośredni",
          image: require("./images/ico-mentor.svg").default,
        },
      ],
    },
    summary2020: {
      numbers: [
        {
          value: 24,
          text: "godzin",
          image: require("../Numbers/images/ico-24.svg").default,
        },
        {
          value: 80,
          text: "uczestników",
          image: require("../Numbers/images/ico-uczestnicy.svg").default,
        },
        {
          value: 30,
          text: "mentorów",
          image: require("../Numbers/images/ico-mentor.svg").default,
        },
        {
          value: 3,
          text: "wyzwania",
          image: require("../Numbers/images/ico-sciezki.svg").default,
        },
        {
          value: 1,
          text: "planeta",
          image: require("../Numbers/images/ico-planeta.svg").default,
        },
      ],
    },
  },
}
