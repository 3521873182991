import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import Dots from "../Dots"
import Gadget from "../Gadget"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import * as s from "./style.module.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


export default function Partners({ lang }) {
  let t = useLabels(labels, lang)

  return (
    <div className={s.root}>
      <Container className={s.container}>
        <div className={s.header}>
          <h2 className={s.title}>{t.partners.title}</h2>
          <img
            className={s.line}
            src={require("../Text/icons/line.svg").default}
          />

          {t.partners.logos.map(({ title, elements }) => (
            <PartnersCard title={title} elements={elements} />
          ))}

          {/* <img
            className={s.line}
            src={require("../Text/icons/line.svg").default}
          />

          {t.partnersOthers.logos.map(({title, elements}) => (
            <PartnersCard title={title} elements={elements} />
          ))} */}

          <div className={s.bottomBarWrapper}>
            <LazyLoadImage height={"100px"} effect="blur"  alt="partners logo" src={require("./images/zestawienie logotypów - KPO.jpg").default}/>
          </div>
          
        </div>
      </Container>
      <img className={s.splash} src={require("./images/splash.svg").default} />
    </div>
  )
}

export function PartnersCard({ title, elements }) {

  const partnersOneLine = title.includes("Partnerzy merytoryczni i partnerzy przestrzeni") || title.includes("Organizatorzy");

  let numOfElementsInRow = 5;

  if (partnersOneLine) {
    numOfElementsInRow = 2;
  }

  return (
    <div>
      <h5>{title}</h5>

      <Row className={!partnersOneLine ? s.clazz : s.clazzSpace} xs={2} md={numOfElementsInRow}>
        {elements.map(({ image, url }) => (
          <a href={url} target="_blank">
            <Col>
              <div className={s.company}>
                <LazyLoadImage width={"200px"} height={"100px"} effect="blur"  alt="partner logo" src={image}/>
              </div>
            </Col>
          </a>
        ))}
      </Row>
    </div>
  )
}
