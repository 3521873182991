import React, { useEffect, useState } from "react"
import * as s from "./style.module.css"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import { Col, Row, Container } from "reactstrap"
import Gadget from "../Gadget"

export default function Numbers({ lang, type }) {
  const [content, setContent] = useState({})
  const t = useLabels(labels, lang)

  useEffect(() => {
    switch (true) {
      case type === "home":
        setContent(t.home)
        break
      case type === "summary2024":
        setContent(t.summary2024)
        break
      case type === "summary2023":
        setContent(t.summary2023)
        break
      case type === "summary2022":
        setContent(t.summary2022)
        break
      case type === "summary2020":
        setContent(t.summary2020)
        break
      default:
        break
    }
  }, [t])

  useEffect(() => {
    console.log({ type })
  }, [type])

  return (
    <div className={s.bg}>
      <Container>
        <div className={s.root}>
          {content?.title && (
            <div className={s.header}>
              <p
                className={s.title}
                dangerouslySetInnerHTML={{ __html: content?.title }}
              />
              <img
                className={s.line}
                src={require("../Text/icons/line.svg").default}
              />
            </div>
          )}
          <Row xs={1} sm={2} className="justify-content-between">
            {content &&
              Object.values(content).length > 0 &&
              content.numbers.map(({ value, text, image }, index) => (
                <Col md={index > 3 ? 6 : 3} xs={6}>
                  <div className={s.singleNumber}>
                    <div>
                      <img className={s.icon} alt="ikona" src={image} />
                    </div>
                    <div className={s.contentWraper}>
                      <div
                        className={s.bigNumber}
                        style={
                          index > 3
                            ? {
                                fontSize: 2.1 + "em",
                                lineHeight: 1.4 + "em",
                              }
                            : null
                        }
                        dangerouslySetInnerHTML={{ __html: value }}
                      />
                      <div
                        className={s.value}
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}
