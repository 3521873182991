// extracted by mini-css-extract-plugin
export var bottomBarWrapper = "style-module--bottomBarWrapper--b5dW0";
export var box = "style-module--box--s4mmW";
export var clazz = "style-module--clazz--rc7lU";
export var clazzSpace = "style-module--clazzSpace--diS6V";
export var company = "style-module--company--PV+Xf";
export var container = "style-module--container--NENj7";
export var description = "style-module--description--iHEyM";
export var divider = "style-module--divider--JUwXz";
export var dividerFull = "style-module--dividerFull--DUNl1";
export var gadgetPosition = "style-module--gadgetPosition--tjSwe";
export var header = "style-module--header--xdQbV";
export var root = "style-module--root--u4QrL";
export var splash = "style-module--splash--NZ22e";
export var title = "style-module--title--hPnCv";