export default {
  en: {
    title: "",
  },
  pl: {
    title: "Partnerzy 2024",
    description:
      "Chcesz wesprzeć inicjatywę oferując swoją wiedzę i talent lub dostęp do danych? Napisz do nas! Przygotujemy dla Ciebie ofertę współpracy i razem zrobimy coś dobrego. Bądź częścią zmiany!",
    button: "Wypełnij formularz &rarr;",
    boxTitle: "Dołącz do nas",
    partners: {
      title: "Partnerzy 2024",
      logos: [
        {
          title: "Organizatorzy:",
          elements: [
            {
              image:
                require("./images/PODSTAWOWA codeme_logo_black_red_subtitle_black_rgb.png").default,
              url: "http://codeme.pl/",
            },
            {
              image:  require("./images/LOGO_KA_gold-01.jpg").default,
              url: "https://kaminskiacademy.com/",
            },
            {
              image:  require("./images/urzadpom.png").default,
              url: "https://pomorskie.eu/urzad-marszalkowski-wojewodztwa-pomorskiego/",
            },
            {
              image:  require("./images/Pomorskie_Kompetencje_Jutra_LOGO_1-01.jpg").default,
              url: "https://gospodarka.pomorskie.eu/kategoria/pomorskie-kompetencje-jutra/",
            },
          ],
        },
        {
          title: "Patronat honorowy:",
          elements: [
            // {
            //   image: require("./images/ministerstwo.png").default,
            //   url: "https://www.gov.pl/web/cyfryzacja",
            // },
            // {
            //   image: require("./images/MWP-PATRONAT-Mieczysław-Struk-poziom-kolor-2021.png")
            //       .default,
            //   url: "https://pomorskie.eu/",
            // },
            {
              image: require("./images/PrezydentMG.jpg").default,
              url: "https://www.gdansk.pl/",
            },
            {
              image: require("./images/pg_poziome_logo_kolor-300x212 alternatywna_2.jpg").default,
              url: "https://pg.edu.pl/",
            },
          ],
        },
        {
          title: "Partnerzy strategiczni:",
          elements: [
            {
              image: require("./images/theprotocol_logo_light (1).png").default,
              url: "https://theprotocol.it/",
            },
          ],
        },
        {
          title: "Partnerzy srebrni:",
          elements: [ 
            {
              image: require("./images/Capgemini_Logo_Color_RGB.png").default,
              url: "https://www.capgemini.com/",
            },
            {
              image: require("./images/speednet.png").default,
              url: "https://speednetsoftware.com/",
            },
          ],
        },
       {
          title: "Partnerzy przestrzeni:",
          elements: [
            {
              image: require("./images/PSI_PSSE.png").default,
              url: "https://www.strefa.gda.pl/",
            },
            {
              image: require("./images/GPNT.png").default,
              url: "https://gpnt.pl/",
            },
            // {
            //   image: require("./images/Słupski Inkubator Przedsiębiorczości.png").default,
            //   url: "http://www.sit.slupsk.pl/"
            // },
            // {
            //   image: require("./images/logo_PARR_pl.png").default,
            //   url: "https://www.parr.slupsk.pl/index.php/pl/"
            // },
          ],
        },
        {
          title: "Partnerzy merytoryczni:",
          elements: [
            {
              image: require("./images/PJATK_shop-1.png").default,
              url: "https://pja.edu.pl/",
            },
            {
              image: require("./images/scalac_logo_2160x1080_(1).png").default,
              url: "https://scalac.io/",
            },
            {
              image: require("./images/wydzia-psychologii-w-sopocie_logo_horizontal_bt.png").default,
              url: "https://swps.pl/struktura/wydzial-psychologii-sopot",
            },   
            {
              image: require("./images/hackerspace.png").default,
              url: "https://hs3.pl/",
            },
            {
              image: require("./images/rsz_interizon.jpg").default,
              url: "https://interizon.pl/",
            },
            {
              image: require("./images/PwC.png").default,
              url: "https://www.pwc.pl/",
            },
            {
              image: require("./images/KAINOS.png").default,
              url: "https://kainos.pl/",
            },
            {
              image: require("./images/pixelmates.png").default,
              url: "",
            },
            {
              image: require("./images/art & science_logo.png").default,
              url: "",
            },
            {
              image: require("./images/DG_logo_mid_res.jpg").default,
              url: "https://www.facebook.com/RyczelDobrzeGada/",
            },
            {
              image: require("./images/jogowaga-logo.jpeg").default,
              url: "https://www.facebook.com/jogowaga",
            },
          ],
        },
        // {
        //   title: "Sponsorzy:",
        //   elements: [
        //     {
        //       image: require("./images/emka.jpg").default,
        //       url: "https://ekoemka.com.pl/",
        //     },
        //   ],
        // },
        {
          title: "Partnerzy społeczni:",
          elements: [
            {
              image: require("./images/logo RC kwadrat.png").default,
              url: "https://fundacjarc.org.pl/",
            },
          ],
        },
        {
          title: "Partnerzy medialni i społecznościowi:",
          elements: [
            {
              image: require("./images/zielona-gospodarka-logo.png").default,
              url: "https://zielonagospodarka.pl/",
            },
            {
              image: require("./images/Radio_Mors_New_Logo_RGB_Podstawowy_pozytyw.svg").default,
              url: "https://mors.ug.edu.pl/",
            },
            {
              image: require("./images/logo-ngo.png").default,
              url: "https://www.ngo.pl/", 
            },
            {
              image: require("./images/Sektor_logo.jpg").default,
              url: "https://sektor3-0.pl/", 
            },
            // {
            //   image: require("./images/BulldogJob-logo.34bbbab5.svg").default,
            //   url: "https://bulldogjob.pl/",
            // },
            // {
            //   image: require("./images/logo-jjit.png").default,
            //   url: "https://justjoin.it/",
            // },
            {
              image: require("./images/BEST_warsaw-1024x519.png").default,
              url: "https://new.best.warszawa.pl/",
            },
            // {
            //   image: require("./images/best_gdansk.png").default,
            //   url: "https://best.gdansk.pl/",
            // },
            // {
            //   image: require("./images/FNL-WI-1.jpg").default,
            //   url: "https://www.worthingtonindustries.com/",
            // },
            // {
            //   image: require("./images/UG_logo_RGB_podstawowy_pozytyw_PL.jpg").default,
            //   url: "https://ug.edu.pl/",
            // },
            // {
            //   image: require("./images/ekopuchatek.png").default,
            //   url: "https://ekopuchatek.pl/",
            // },
            // {
            //   image: require("./images/received_1053940191913845.webp").default,
            //   url: "https://www.facebook.com/samorzaduniwersytetuswpssopot/",
            // },
            {
              image: require("./images/pp.jpg").default,
              url: "https://pracodawcypomorza.pl/",
            }, 
            {
              image: require("./images/starter.png").default,
              url: "https://inkubatorstarter.pl/",
            },
            {
              image: require("./images/instytut kultury miejskiej.png").default,
              url: "https://ikm.gda.pl/",
            },
            {
              image: require("./images/kosmonautaLogo.png").default,
              url: " ",
            },
            {
              image: require("./images/logo_sozo-niebieskie_pag.jpg").default,
              url: "https://sozosfera.pl/",
            },
            {
              image: require("./images/logo a11y_.png").default,
              url: "https://www.facebook.com/profile.php?id=61559965273243",
            },
            {
              image: require("./images/hevelianum_logo.png").default,
              url: "https://hevelianum.pl/",
            },
            {
              image: require("./images/Merito.png").default,
              url: "https://www.merito.pl/gdansk/",
            },
          ],
        },
        {
          title: "Partnerzy wspierający:",
          elements: [
            {
              image:  require("./images/kajaki_3.jpg").default,
              url: "https://www.facebook.com/kajaki3miasto/",
            },
            {
              image: require("./images/pzw rumia.png").default,
              url: "https://pzwrumia.pl/",
            },
            {
              image:  require("./images/logo Beka.jpg").default,
              url: "https://npk.org.pl/o-parku-2/formy-ochrony-przyrody-1/rezerwaty-przyrody-3/beka/",
            },
            {
              image:  require("./images/bat.jpg").default,
              url: "https://bat.pl/",
            },
            {
              image:  require("./images/kosakowo_logo_CMYK (1).png").default,
              url: "https://gminakosakowo.pl/",
            },
            {
              image:  require("./images/peko2.jpg").default,
              url: "https://pukpeko.pl/",
            },
            {
              image:  require("./images/logo_WODY-POLSKIE_pl-poziom.jpg").default,
              url: "https://www.wody.gov.pl/",
            },
          ],
        },
      ],
    },
    partnersOthers: {
      logos: [
        // {
        //   title: " ",
        //   elements: [
        //     {
        //       image: require("./images/POMORSKIE-kolor-RGB.png").default,
        //       url: "https://pomorskie.eu/",
        //     },
        //     {
        //       image: require("./images/ISP_kolor.jpg").default,
        //       url: "https://pomorskie.eu/smart-progress/",
        //     }
        //   ],
        // },
      ],
    },
  },
}
