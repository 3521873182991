// extracted by mini-css-extract-plugin
export var bg = "style-module--bg--OHeCD";
export var bigNumber = "style-module--bigNumber--PTcBo";
export var header = "style-module--header--aE3KZ";
export var icon = "style-module--icon--F8Xhg";
export var line = "style-module--line--bfigc";
export var root = "style-module--root--puSOY";
export var singleNumber = "style-module--singleNumber--88q3T";
export var text = "style-module--text--D-7V7";
export var title = "style-module--title--u0xwr";
export var value = "style-module--value--COWe8";